@import '../../styles/app';
.root {
  display: flex
}
.appBar {
  z-index: 999;
  transition: width 1.0s, margin 400ms !important;
  background-color: #1d3660 !important;
}
.appBarShift {
  margin-left: $sidebar-width-open !important;
  width: calc(100% - 284px) !important;
  transition: width 400ms, margin 1s !important;
}
.appBarNoShift {
  transition: width 400ms, margin 1s !important;
}
.menuButton {
  margin-right: 36px;
}
.hide {
  display: none;
}
.drawer {
  z-index: 1;
  width: $sidebar-width-open;
  flex-shrink: 0;
  white-space: nowrap;
}
.drawerOpen {
  width: $sidebar-width-open;
  transition: width 400ms !important;
}
.drawerClose {
  transition:  width 400ms !important;
  overflow-x: hidden;
  width: 60px;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px
}
.content {
  flex-grow: 1;
  padding-top: 50px;
}

.activeIcon {
  color: $primary;
  transform: scale(1.4);
}

.inactiveIcon {
  color: $secondary;
  transform: scale(1.4);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px;
  font-size: 20px;
  width: 100%;
  transition: width $sidebar-transition-time ease-in-out;
  color: var(--logo-color);
}

.logoStyle {
  width: 50px;
}

