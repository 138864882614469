/**
 * Custom application mixins available through out the app
 */

// define sass mixin
// Generates single property classes
// -------------------------
@mixin property-variants($base, $property, $variants: ()) {
  @each $size, $value in $variants {
    @if $size == 'md' {
      #{$base} {
        #{$property}: $value;
      }

      #{$base}-n {
        #{$property}: $value;
      }
    }

    #{$base}-#{$size} {
      #{$property}: $value;
    }

    #{$base}-n-#{$size} {
      #{$property}: -$value;
    }
  }
}

// Common thumbnail properties
// -------------------------
@mixin thumb($parent, $size) {
  #{$parent} {
    width: $size;

    > .glyphicon,
    > .fa {
      line-height: $size;
    }
  }
}

@mixin border-radius($radius) {
  border-radius: $radius;
}

@mixin scroll-bar($color) {
  &::-webkit-scrollbar {
    height: 8px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: $color;
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

/* Chat Scrollbar */

@mixin chat-scrollbar($color, $width) {
  &::-webkit-scrollbar {
    width: $width;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
    border-radius: $border-radius;
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    border: none;
    background-color: $color;
  }
}

/* For Material Buttons, since they don't support abnormal colors */

.iconButtonSuccess {
  color: $success;
  transform: scale(1.2);
  transition: 0.3s;
}
.iconButtonWarn {
  color: $warning;
  transform: scale(1.2);
  transition: 0.3s;
}
.iconButtonDanger {
  color: $danger;
  transform: scale(1.2);
  transition: 0.3s;
}

.iconButtonSuccess:hover {
  color: $success-dark;
  transform: scale(1.2);
  transition: 0.3s;
}
.iconButtonWarn:hover {
  color: $warning-dark;
  transform: scale(1.2);
  transition: 0.3s;
}
.iconButtonDanger:hover {
  color: $danger-dark;
  transform: scale(1.2);
  transition: 0.3s;
}

.buttonSuccess {
  background-color: $success !important;
}
.buttonWarn {
  background-color: $warning !important;
}
.buttonDanger {
  background-color: $danger !important;
}

.buttonSuccess:hover {
  background-color: $success-dark !important;
}
.buttonWarn:hover {
  background-color: $warning-dark !important;
}
.buttonDanger:hover {
  background-color: $danger-dark !important;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalPaper {
  position: absolute;
  width: 50%;
  border: 2px solid $secondary;
  box-shadow: #000d16;
  padding: 20px;
}
